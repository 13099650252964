import lodash from 'lodash';

export default {
  fileRequired(val) {
    return val.size > 0;
  },
  notFile(val) {
    return !(val instanceof File);
  },
  notEmpty(val) {
    return !lodash.isEmpty(val);
  },
};
