<template>
  <b-form inline class="mb-sm-3">
    <b-form-group class="mr-3">
      <b-input
        style="max-width: 80px !important"
        v-symbolic-filter="/[^0-9]/g"
        :class="{
          error: validations && validations.model.blogger_profile.females_percent.$anyError,
        }"
        v-model.number="model.blogger_profile.females_percent"
        type="number"
        class="ph-36 statistics__percent"
        name="stories"
        id="stories5"
      />
    </b-form-group>

    <StatisticFileInput
      :class="{
        error: validations && validations.model.blogger_profile.women_percent_screens.$anyError,
      }"
      v-model="model.blogger_profile.women_percent_screens"
      :moderationFormStep="3"
    />
  </b-form>
</template>

<script>
import validateFunc from '@main/func/validations';
import {
  required,
  minLength,
  between,
  minValue,
  maxValue,
  requiredIf,
  email,
} from 'vuelidate/lib/validators';
import StatisticFileInput from '../../settings/StatisticFileInput.vue';

export default {
  components: { StatisticFileInput },
  model: {
    prop: 'model',
    event: 'input',
  },
  props: {
    model: Object,
    validations: Object,
  },
};
</script>
