<template>
  <div style="position: unset">
    <b-form-group class="mr-1">
      <SingleFileInput
        v-on="$listeners"
        v-bind="{ ...$props, ...$attrs }"
        :class="{ error: invalid }"
        :handleUpload="true"
        :uploadUrl="'/instagram/upload_screenshots/'"
        v-model="value"
      />
    </b-form-group>
    <b-form-invalid-feedback :state="!invalid">
      Загрузите корректный скриншот.
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import SingleFileInput from '../forms/SingleFileInput.vue';

export default {
  components: { SingleFileInput },
  props: {
    moderationBadSteps: Array,
    moderationFormStep: Number,
    value: [File, Object, Array],
  },
  computed: {
    invalid() {
      if (!this.moderationBadSteps) return;
      return this.moderationBadSteps.includes(this.moderationFormStep) && !this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.invalid-feedback {
  position: absolute;
  top: 82%;
  left: 0%;
}
</style>
