<template>
  <b-form inline class="mb-sm-4">
    <b-form-group class="mr-3 statistic__select d-block">
      <CitySelect
        :class="{ error: validations ? validations.city.$anyError : $v.model.city.$anyError }"
        style="width: 100% !important"
        v-model="model.city"
        deselectLabel=""
        selectedLabel=""
      />
    </b-form-group>
    <b-form-group class="mr-3">
      <b-input
        style="max-width: 60px !important"
        v-symbolic-filter="/[^0-9]/g"
        :class="{
          error: validations ? validations.coverage.$anyError : $v.model.coverage.$anyError,
        }"
        v-model.number="model.coverage"
        type="number"
        class="ph-36 text-left"
      />
    </b-form-group>

    <StatisticFileInput
      v-if="!$attrs.disableScreen"
      :class="{ error: validations && validations.screens.$anyError }"
      v-model="model.screens"
      :moderationFormStep="6"
      v-bind="{ ...$props, ...$attrs }"
    />
  </b-form>
</template>

<script>
import validateFunc from '@main/func/validations';
import {
  required,
  minLength,
  between,
  minValue,
  maxValue,
  requiredIf,
  email,
} from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import StatisticFileInput from '../../settings/StatisticFileInput.vue';
import CitySelect from '../../reusable/elements/selects/CitySelect.vue';

export default {
  components: { StatisticFileInput, CitySelect },
  computed: {
    ...mapState(['bloggerOptions']),
  },
  model: {
    prop: 'model',
    event: 'input',
  },
  props: {
    model: Object,
    validations: Object,
  },
  validations: {
    model: {
      city: { required, notUndef: async (val) => val && val.short_key !== 'NAN' },
      coverage: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(100),
        whole: (val) => val % 1 === 0,
      },
    },
  },
};
</script>
