<template>
  <b-form inline class="mb-sm-4">
    <b-form-group class="mr-3 statistic__select d-block">
      <Multiselect
        style="width: 100% !important"
        :class="{
          error: validations ? validations.age_group.$anyError : $v.model.age_group.$anyError,
        }"
        :value="
          bloggerOptions.actions.POST.audiences.child.children.age_group.choices.filter(
            (val) => val.value === model.age_group
          )[0]
        "
        @input="(val) => (model.age_group = val.value)"
        placeholder="Возраст"
        track-by="value"
        label="display_name"
        :show-labels="false"
        :options="bloggerOptions.actions.POST.audiences.child.children.age_group.choices"
      />
    </b-form-group>
    <b-form-group class="mr-3">
      <b-input
        style="max-width: 60px !important"
        v-symbolic-filter="/[^0-9]/g"
        :class="{
          error: validations
            ? validations.age_group_percent.$anyError
            : $v.model.age_group_percent.$anyError,
        }"
        v-model.number="model.age_group_percent"
        type="number"
        class="ph-36 text-left"
        name="stories"
        id="stories7"
      />
    </b-form-group>

    <StatisticFileInput
      v-if="!disableScreen"
      :class="{ error: validations ? validations.screens.$anyError : false }"
      :style="sizing ? `width: ${sizing[2]}% !important` : ''"
      v-model="model.screens"
      :moderationFormStep="4"
      v-bind="{ ...$props, ...$attrs }"
    />
  </b-form>
</template>

<script>
import validateFunc from '@main/func/validations';
import {
  required,
  minLength,
  between,
  minValue,
  maxValue,
  requiredIf,
  email,
} from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import StatisticFileInput from '../../settings/StatisticFileInput.vue';

export default {
  components: { StatisticFileInput, Multiselect },
  computed: {
    ...mapState(['bloggerOptions']),
  },
  model: {
    prop: 'model',
    event: 'input',
  },
  props: {
    model: Object,
    validations: Object,
    disableScreen: Boolean,
    sizing: [Array, Object],
  },
  validations: {
    model: {
      age_group: { required, notUndef: async (val) => !!val },
      age_group_percent: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(100),
        whole: (val) => val % 1 === 0,
      },
    },
  },
};
</script>
