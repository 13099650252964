var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"mb-sm-4",attrs:{"inline":""}},[_c('b-form-group',{staticClass:"mr-3 statistic__select d-block"},[_c('Multiselect',{class:{
        error: _vm.validations ? _vm.validations.age_group.$anyError : _vm.$v.model.age_group.$anyError,
      },staticStyle:{"width":"100% !important"},attrs:{"value":_vm.bloggerOptions.actions.POST.audiences.child.children.age_group.choices.filter(
          function (val) { return val.value === _vm.model.age_group; }
        )[0],"placeholder":"Возраст","track-by":"value","label":"display_name","show-labels":false,"options":_vm.bloggerOptions.actions.POST.audiences.child.children.age_group.choices},on:{"input":function (val) { return (_vm.model.age_group = val.value); }}})],1),_c('b-form-group',{staticClass:"mr-3"},[_c('b-input',{directives:[{name:"symbolic-filter",rawName:"v-symbolic-filter",value:(/[^0-9]/g),expression:"/[^0-9]/g"}],staticClass:"ph-36 text-left",class:{
        error: _vm.validations
          ? _vm.validations.age_group_percent.$anyError
          : _vm.$v.model.age_group_percent.$anyError,
      },staticStyle:{"max-width":"60px !important"},attrs:{"type":"number","name":"stories","id":"stories7"},model:{value:(_vm.model.age_group_percent),callback:function ($$v) {_vm.$set(_vm.model, "age_group_percent", _vm._n($$v))},expression:"model.age_group_percent"}})],1),(!_vm.disableScreen)?_c('StatisticFileInput',_vm._b({class:{ error: _vm.validations ? _vm.validations.screens.$anyError : false },style:(_vm.sizing ? ("width: " + (_vm.sizing[2]) + "% !important") : ''),attrs:{"moderationFormStep":4},model:{value:(_vm.model.screens),callback:function ($$v) {_vm.$set(_vm.model, "screens", $$v)},expression:"model.screens"}},'StatisticFileInput',Object.assign({}, _vm.$props, _vm.$attrs),false)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }