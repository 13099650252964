<template>
  <b-form class="align-items-end flex-nowrap d-flex flex-row justify-content-between">
    <b-form-group class="w-30 mr-3">
      <div class="settings__info--text--up">Минимум</div>
      <b-input
        v-symbolic-filter="/[^0-9]/g"
        :class="{
          error: validations && validations.model.blogger_profile.coverage_posts_min.$anyError,
        }"
        v-model.number="model.blogger_profile.coverage_posts_min"
        type="number"
        class="ph-36"
        name="stories"
        id="stories3"
      />
    </b-form-group>
    <b-form-group class="w-30 mr-3">
      <div class="settings__info--text--up">Максимум</div>
      <b-input
        v-symbolic-filter="/[^0-9]/g"
        :class="{
          error: validations && validations.model.blogger_profile.coverage_posts_max.$anyError,
        }"
        v-model.number="model.blogger_profile.coverage_posts_max"
        type="number"
        class="ph-36"
        name="stories"
        id="stories4"
      />
    </b-form-group>
    <StatisticFileInput
      :class="{
        error: validations && validations.model.blogger_profile.posts_coverage_screens.$anyError,
      }"
      v-model="model.blogger_profile.posts_coverage_screens"
      :moderationFormStep="2"
      v-bind="{ ...$props, ...$attrs }"
    />
  </b-form>
</template>

<script>
import validateFunc from '@main/func/validations';
import {
  required,
  minLength,
  between,
  minValue,
  maxValue,
  requiredIf,
  email,
} from 'vuelidate/lib/validators';
import StatisticFileInput from '../../settings/StatisticFileInput.vue';

export default {
  components: { StatisticFileInput },
  model: {
    prop: 'model',
    event: 'input',
  },
  props: {
    model: Object,
    validations: Object,
  },
};
</script>
