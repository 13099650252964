<template>
  <Multiselect
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
    :options="cities"
    placeholder="Выберите город"
    track-by="id"
    label="name"
    :searchable="true"
    :options-limit="300"
    :loading="isLoading"
    select-label=""
    deselect-label=""
    :internal-search="false"
    @search-change="asyncFind"
    @click="asyncFind()"
  >
    <span slot="noResult">Город не найден</span>
    <template #tagPlaceholder>Выберите город</template>
  </Multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import _ from 'lodash';
import info from '@main/api/info';

export default {
  data: () => ({
    cities: [],
    isLoading: false,
  }),
  components: {
    Multiselect,
  },
  methods: {
    asyncFind(query) {
      this.isLoading = true;
      info.getCities({ name: query }).then((res) => {
        this.cities = res;
        this.isLoading = false;
      });
    },
  },
  mounted() {
    this.asyncFind();
  },
};
</script>

<style lang="scss" scoped>
.percentage__input--percent {
  height: 33.5px !important;
}
.custom-control {
  padding-left: 2.09rem !important;
}
</style>
